<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="row">
              <div class="col-md-7">
                <div class="form-group">
                  <label class="font-weight-bolder">A Location Selection</label>
                  <vSelect
                    multiple
                    class="form-control form-control--modules-select"
                    placeholder="Select Location"
                    v-model="selectedLocationsA"
                    :options="locations"
                    label="title"
                  >
                    <template #option="{ title }">
                      <span class="mr-3">{{ title }}</span>
                    </template>
                  </vSelect>
                </div>
              </div>
              <div class="col-md-2">
                <button
                  @click="selectAllLocationA(selectLocationsTypeA)"
                  class="btn btn-block mt-8"
                  :class="{
                    'btn-light-primary': selectLocationsTypeA === 'add',
                    'btn-light-danger': selectLocationsTypeA === 'remove',
                  }"
                  style="height: 42px"
                >
                  <span v-if="selectLocationsTypeA === 'add'"
                    >Select All Locations</span
                  >
                  <span v-else>{{ $t('GENERAL.REMOVE_ALL') }}</span>
                </button>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-bolder">A Date Range</label>
                  <DatePicker
                    ref="pickerA"
                    class="col-9"
                    v-model="rangeA"
                    mode="dateTime"
                    :attributes="attributes"
                    locale="hr"
                    is-range
                    is24hr
                    :masks="masks"
                    :max-date="new Date()"
                  >
                    <template v-slot="{ inputValue, togglePopover }">
                      <input
                        @click="togglePopover()"
                        :value="(inputValue.start && inputValue.end) ? inputValue.start + ' - ' + inputValue.end : ''"
                        class="form-control"
                        style="height: 42px"
                        readonly
                      />
                    </template>
                    <template v-slot:footer>
                      <div class="row p-2 pr-0">
                        <div class="col pl-5 pr-0">
                          <div
                            @click="setStartEndDate('rangeA')"
                            class="symbol symbol-40 symbol-light-primary"
                          >
                            <div class="symbol-label cursor-pointer">
                              <span
                                class="svg-icon svg-icon-lx svg-icon-primary"
                              >
                                <inline-svg
                                  v-b-tooltip.hover.top="'00:00-23:59'"
                                  src="media/svg/icons/Home/Clock.svg"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row p-2">
                        <div class="col pl-5 pr-0">
                          <div
                            @click="yesterdayA"
                            class="symbol symbol-40 symbol-light-primary"
                          >
                            <div
                              v-b-tooltip.hover.top="'Yesterday'"
                              class="symbol-label cursor-pointer"
                            >
                              Y
                            </div>
                          </div>
                        </div>
                        <div class="col p-0">
                          <div
                            @click="last7DaysA"
                            class="symbol symbol-40 symbol-light-primary"
                          >
                            <div
                              v-b-tooltip.hover.top="'-7 days'"
                              class="symbol-label cursor-pointer"
                            >
                              -7D
                            </div>
                          </div>
                        </div>
                        <div class="col p-0">
                          <div
                            @click="last30DaysA"
                            class="symbol symbol-40 symbol-light-primary"
                          >
                            <div
                              v-b-tooltip.hover.top="'-30 days'"
                              class="symbol-label cursor-pointer"
                            >
                              -30D
                            </div>
                          </div>
                        </div>
                        <div class="col p-0">
                          <div
                            @click="lastMonthA"
                            class="symbol symbol-40 symbol-light-primary"
                          >
                            <div
                              v-b-tooltip.hover.top="'Last month'"
                              class="symbol-label cursor-pointer"
                            >
                              LM
                            </div>
                          </div>
                        </div>
                        <div class="col pl-0">
                          <div
                            @click="thisMonthA"
                            class="symbol symbol-40 symbol-light-primary"
                          >
                            <div
                              v-b-tooltip.hover.top="'This month'"
                              class="symbol-label cursor-pointer"
                            >
                              TM
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </DatePicker>
                </div>
              </div>
            </div>
            <div class="separator separator-dashed mt-0 mb-10"></div>
            <div class="row">
              <div class="col-md-7">
                <div class="form-group">
                  <label class="font-weight-bolder">B Location Selection</label>
                  <vSelect
                    multiple
                    class="form-control form-control--modules-select"
                    placeholder="Select Location"
                    v-model="selectedLocationsB"
                    :options="locations"
                    label="title"
                  >
                    <template #option="{ title }">
                      <span class="mr-3">{{ title }}</span>
                    </template>
                  </vSelect>
                </div>
              </div>
              <div class="col-md-2">
                <button
                  @click="selectAllLocationB(selectLocationsTypeB)"
                  class="btn btn-block mt-8"
                  :class="{
                    'btn-light-primary': selectLocationsTypeB === 'add',
                    'btn-light-danger': selectLocationsTypeB === 'remove',
                  }"
                  style="height: 42px"
                >
                  <span v-if="selectLocationsTypeB === 'add'"
                    >Select All Locations</span
                  >
                  <span v-else>{{ $t('GENERAL.REMOVE_ALL') }}</span>
                </button>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="font-weight-bolder">B Date Range</label>
                  <DatePicker
                    ref="pickerB"
                    class="col-9"
                    v-model="rangeB"
                    mode="dateTime"
                    :attributes="attributes"
                    locale="hr"
                    is-range
                    is24hr
                    :masks="masks"
                    :max-date="new Date()"
                  >
                    <template v-slot="{ inputValue, togglePopover }">
                      <input
                        @click="togglePopover()"
                        :value="(inputValue.start && inputValue.end) ? inputValue.start + ' - ' + inputValue.end : ''"
                        class="form-control"
                        style="height: 42px"
                        readonly
                      />
                    </template>
                    <template v-slot:footer>
                      <div class="row p-2 pr-0">
                        <div class="col pl-5 pr-0">
                          <div
                            @click="setStartEndDate('rangeB')"
                            class="symbol symbol-40 symbol-light-primary"
                          >
                            <div class="symbol-label cursor-pointer">
                              <span
                                class="svg-icon svg-icon-lx svg-icon-primary"
                              >
                                <inline-svg
                                  v-b-tooltip.hover.top="'00:00-23:59'"
                                  src="media/svg/icons/Home/Clock.svg"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row p-2">
                        <div class="col pl-5 pr-0">
                          <div
                            @click="yesterdayB"
                            class="symbol symbol-40 symbol-light-primary"
                          >
                            <div
                              v-b-tooltip.hover.top="'Yesterday'"
                              class="symbol-label cursor-pointer"
                            >
                              Y
                            </div>
                          </div>
                        </div>
                        <div class="col p-0">
                          <div
                            @click="last7DaysB"
                            class="symbol symbol-40 symbol-light-primary"
                          >
                            <div
                              v-b-tooltip.hover.top="'-7 days'"
                              class="symbol-label cursor-pointer"
                            >
                              -7D
                            </div>
                          </div>
                        </div>
                        <div class="col p-0">
                          <div
                            @click="last30DaysB"
                            class="symbol symbol-40 symbol-light-primary"
                          >
                            <div
                              v-b-tooltip.hover.top="'-30 days'"
                              class="symbol-label cursor-pointer"
                            >
                              -30D
                            </div>
                          </div>
                        </div>
                        <div class="col p-0">
                          <div
                            @click="lastMonthB"
                            class="symbol symbol-40 symbol-light-primary"
                          >
                            <div
                              v-b-tooltip.hover.top="'Last month'"
                              class="symbol-label cursor-pointer"
                            >
                              LM
                            </div>
                          </div>
                        </div>
                        <div class="col pl-0">
                          <div
                            @click="thisMonthB"
                            class="symbol symbol-40 symbol-light-primary"
                          >
                            <div
                              v-b-tooltip.hover.top="'This month'"
                              class="symbol-label cursor-pointer"
                            >
                              TM
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </DatePicker>
                </div>
              </div>
            </div>
            <div class="row">
              <button
                @click.prevent="generate"
                class="btn btn-block btn-light-primary font-weight-bold"
              >
                Generate
              </button>
            </div>
            <div v-if="isGeneratedA && isGeneratedB" class="row mt-5">
              <button
                @click.prevent="resetAll"
                class="btn btn-block btn-light-danger font-weight-bold"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <b-spinner
          v-if="showSpinner"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </div>
    </div>
    <div v-if="isGeneratedA" class="row">
      <div class="col-md-12">
        <LocationsATraffic
          v-if="isGeneratedA"
          :traffic="generatedResultsA"
          :selected-a="selectedLocationsA"
          :range="rangeA"
        />
      </div>
    </div>
    <div v-if="isGeneratedB" class="row">
      <div class="col-md-12">
        <LocationsBTraffic
          v-if="isGeneratedB"
          :traffic="generatedResultsB"
          :selected-b="selectedLocationsB"
          :range="rangeB"
        />
      </div>
    </div>
    <div v-if="isGeneratedB" class="row">
      <div class="col-md-12">
        <ABTraffic
          v-if="isGeneratedA && isGeneratedB"
          :traffic-a="generatedResultsA"
          :traffic-b="generatedResultsB"
          :range-a="rangeA"
          :range-b="rangeB"
        />
      </div>
    </div>
    <div v-if="isGeneratedA" class="row">
      <div class="col-md-12">
        <WeekDaysTrafficDual
          v-if="isGeneratedA && isGeneratedB"
          :traffic-a="generatedResultsA"
          :traffic-b="generatedResultsB"
          :range-a="rangeA"
          :range-b="rangeB"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { dateFilter } from 'vue-date-fns'
import ApiService from '@/core/services/api.service'
import add from 'date-fns/add'
import startOfMonth from 'date-fns/startOfMonth'
import getMonth from 'date-fns/getMonth'
import addMonths from 'date-fns/addMonths'
import getYear from 'date-fns/getYear'
import endOfMonth from 'date-fns/endOfMonth'
import vSelect from 'vue-select'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import format from 'date-fns/format'
import LocationsATraffic from '@/view/pages/comparative-analysis/_components/LocationsATraffic'
import LocationsBTraffic from '@/view/pages/comparative-analysis/_components/LocationsBTraffic'
import WeekDaysTrafficDual from '@/view/pages/comparative-analysis/_components/WeekDaysTrafficDual'
import ABTraffic from '@/view/pages/comparative-analysis/_components/ABTraffic'
import addDays from 'date-fns/addDays'

export default {
  name: 'LocationComparativeAnalysis',
  filters: {
    date: dateFilter,
  },
  components: {
    DatePicker,
    vSelect,
    LocationsATraffic,
    LocationsBTraffic,
    WeekDaysTrafficDual,
    ABTraffic,
  },
  data() {
    return {
      locations: [],
      selectedLocationsA: [],
      selectedLocationsB: [],
      rangeA: { start: null, end: null },
      rangeB: { start: null, end: null },
      generatedResultsA: [],
      generatedResultsB: [],
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      isGeneratedA: false,
      isGeneratedB: false,
      showSpinner: false,
      selectLocationsTypeA: 'add',
      selectLocationsTypeB: 'add',
    }
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompany']),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Location comparative reports',
        route: '/comparative-analysis/location',
      },
    ])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      ApiService.get('locations', '?properties[]=name&pagination=false').then(
        ({ data }) => {
          this.totalItems = data['hydra:member'].length
          this.locations = [...data['hydra:member']]
        }
      )
    }

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER'
    ) {
      ApiService.get(
        'locations',
        `?company=${this.currentUserCompany}&pagination=false`
      ).then(({ data }) => {
        this.totalItems = data['hydra:member'].length
        this.locations = [...data['hydra:member']]
      })
    }
  },
  methods: {
    generate() {
      this.showSpinner = true
      let num = 0
      this.isGeneratedA = false
      this.isGeneratedB = false
      this.generatedResultsA = []
      this.generatedResultsB = []

      // a section
      const afterA = format(this.rangeA.start, "yyyy-MM-dd'T'HH:mm:ss")
      const beforeA = format(this.rangeA.end, "yyyy-MM-dd'T'HH:mm:ss")

      this.selectedLocationsA.forEach((location) => {
        ApiService.get(
          'transactions',
          `?location=${location['@id']}&createdAt[after]=${afterA}&createdAt[before]=${beforeA}&order[createdAt]=desc&pagination=false`
        ).then(({ data }) => {
          data['hydra:member'].forEach(
            ({
              imei,
              deviceMeta,
              amount,
              paymentType,
              createdAt,
              locationMeta,
            }) => {
              if(paymentType !== 'pay_token') {
                  this.generatedResultsA.push({
                  imei: imei,
                  deviceName: deviceMeta ? deviceMeta.name : '',
                  location: locationMeta ? locationMeta.title : '',
                  lessor: locationMeta ? locationMeta.lessor : '',
                  address: locationMeta ? locationMeta.address : '',
                  tags: locationMeta ? locationMeta.tags : [],
                  amount: amount,
                  paymentType: paymentType,
                  createdAt: format(new Date(createdAt), 'dd.MM.yyyy @ HH:mm:ss'),
                })
              }
            }
          )
          num++
          if (num === this.selectedLocationsA.length) {
            this.isGeneratedA = true
          }
        })
      })

      // b section
      const afterB = format(this.rangeB.start, "yyyy-MM-dd'T'HH:mm:ss")
      const beforeB = format(this.rangeB.end, "yyyy-MM-dd'T'HH:mm:ss")

      let num2 = 0
      this.selectedLocationsB.forEach((location) => {
        ApiService.get(
          'transactions',
          `?location=${location['@id']}&createdAt[after]=${afterB}&createdAt[before]=${beforeB}&order[createdAt]=desc&pagination=false`
        ).then(({ data }) => {
          data['hydra:member'].forEach(
            ({
              imei,
              deviceMeta,
              amount,
              paymentType,
              createdAt,
              locationMeta,
              isSaldoReducer
            }) => {
             if(paymentType !== 'pay_token' && !isSaldoReducer) {
                this.generatedResultsB.push({
                  imei: imei,
                  deviceName: deviceMeta ? deviceMeta.name : '',
                  location: locationMeta ? locationMeta.title : '',
                  lessors: locationMeta ? locationMeta.lessor : '',
                  address: locationMeta ? locationMeta.address : '',
                  tags: locationMeta ? locationMeta.tags : [],
                  amount: amount,
                  paymentType: paymentType,
                  createdAt: format(new Date(createdAt), 'dd.MM.yyyy @ HH:mm:ss'),
                })
              }
            }
          )

          num2++
          if (num2 === this.selectedLocationsB.length) {
            this.isGeneratedB = true
            this.showSpinner = false
          }
        })
      })
    },
    resetAll() {
      this.selectedLocationsA = []
      this.selectedLocationsB = []
      this.rangeA = { start: null, end: null }
      this.rangeB = { start: null, end: null }
      this.generatedResultsA = []
      this.generatedResultsB = []
      this.isGeneratedA = false
      this.isGeneratedB = false
      this.showSpinner = false
    },
    selectAllLocationA(type) {
      if (type === 'add') {
        this.selectedLocationsA = []
        this.locations.forEach((location) => {
          this.selectedLocationsA.push(location)
        })
        this.selectLocationsTypeA = 'remove'
      } else {
        this.selectedLocationsA = []
        this.selectLocationsTypeA = 'add'
      }
    },
    selectAllLocationB(type) {
      if (type === 'add') {
        this.selectedLocationsB = []
        this.locations.forEach((location) => {
          this.selectedLocationsB.push(location)
        })
        this.selectLocationsTypeB = 'remove'
      } else {
        this.selectedLocationsB = []
        this.selectLocationsTypeB = 'add'
      }
    },
    setStartEndDate(range) {
      if (range === 'rangeA') {
        let a = addDays(this.rangeA.start, -1)
        a.setHours(24, 0, 0)
        this.rangeA.start = a

        let b = addDays(this.rangeA.end, 0)
        b.setHours(23, 59, 59)
        this.rangeA.end = b

        this.$refs.pickerA.updateValue({
          start: this.rangeA.start,
          end: this.rangeA.end,
        })
      } else {
        let a = addDays(this.rangeB.start, -1)
        a.setHours(24, 0, 0)
        this.rangeB.start = a

        let b = addDays(this.rangeB.end, 0)
        b.setHours(23, 59, 59)
        this.rangeB.end = b

        this.$refs.pickerB.updateValue({
          start: this.rangeB.start,
          end: this.rangeB.end,
        })
      }
    },

    yesterdayA() {
      this.$refs.pickerA.updateValue({
        start: addDays(new Date(), -1),
        end: addDays(new Date(), -1),
      })
    },
    last7DaysA() {
      this.$refs.pickerA.updateValue({
        start: add(new Date(), { days: -8 }),
        end: add(new Date(), { days: -1 }),
      })
    },
    last30DaysA() {
      this.$refs.pickerA.updateValue({
        start: add(new Date(), { days: -31 }),
        end: add(new Date(), { days: -1 }),
      })
    },
    thisMonthA() {
      this.$refs.pickerA.updateValue({
        start: startOfMonth(new Date()),
        end: new Date(),
      })
    },
    lastMonthA() {
      this.$refs.pickerA.updateValue({ start: null, end: null })

      this.$refs.pickerA.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.pickerA.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },

    yesterdayB() {
      this.$refs.pickerB.updateValue({
        start: addDays(new Date(), -1),
        end: addDays(new Date(), -1),
      })
    },
    last7DaysB() {
      this.$refs.pickerB.updateValue({
        start: add(new Date(), { days: -8 }),
        end: add(new Date(), { days: -1 }),
      })
    },
    last30DaysB() {
      this.$refs.pickerB.updateValue({
        start: add(new Date(), { days: -31 }),
        end: add(new Date(), { days: -1 }),
      })
    },
    thisMonthB() {
      this.$refs.pickerB.updateValue({
        start: startOfMonth(new Date()),
        end: new Date(),
      })
    },
    lastMonthB() {
      this.$refs.pickerB.updateValue({ start: null, end: null })

      this.$refs.pickerB.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.pickerB.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--logger-select,
  &--modules-select,
  &--users-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
